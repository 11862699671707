import React from 'react'
import styled from 'styled-components'

import { accentDark } from 'cad/themes/accentDark'

const P = styled.p`
    font-size: 22px;
    margin: 15px 0;
    line-height: 26px;
    li {
        font-size: 18px;
    }
`

const A = styled.a`
    color: ${accentDark.color};
    text-decoration: none;
    margin-left: 5px;
    &:hover {
        text-decoration: underline;
    }
`

const H3 = styled.h3`
    font-size: 24px;
    line-height: 30px;
    margin: 20px 0;
`

const Ul = styled.ul`
    margin: 5px 20px;
    span {
        height: 20px;
    }
`

const Li = styled.li`
    margin: 5px 20px;
    list-style: circle;
`

const email = 'obmer@clearline.com.ua'

const IntroductionContentEN = () => (
    <>
        <H3>
            Measure each room of the room in turn, for the drawing the following
            dimensions are required
        </H3>
        <Ul>
            <Li>height and width of door and window openings</Li>
            <Li>
                distances from the corners of the room to window and door
                openings
            </Li>
            <Li>
                the total length of each wall from corner to corner of the room
            </Li>
            <Li>distances from floor to ceiling</Li>
            <Li>distance from floor to window sill</Li>
            <Li>
                location of hoods in the kitchen and bathroom, heating
                radiators, risers, tied to the nearest wall
            </Li>
        </Ul>

        <H3>Draw each room in turn, starting with the hallway</H3>
        <Ul>
            <Li>
                First calculate the perimeter of the room and make sure you
                close drawing in a single outline.
            </Li>

            <Li>
                Next, enter the height, width, depth of the entrance doorway
            </Li>

            <Li>
                Next, start drawing from the right corner of the door, choosing
                arrows direction of the walls and setting their dimensions
            </Li>
            <Li>
                Close the perimeter of the hallway into a single circuit by
                connecting the last segment with the left corner of the doorway.
            </Li>
            <p>
                <b>!!!</b> if the perimeter could not be closed into a single
                contour, then it means somewhere an error was made in the
                calculation of the perimeter, recalculate and draw again
            </p>
        </Ul>

        <H3>Add windows, hood, radiators, pipes</H3>

        <Ul>
            <Li>
                after the room is drawn, add items from the panel tools on the
                left.
            </Li>
            <Li>
                to do this, click on the object on the left of the toolbar and
                then to the desired wall, then enter the anchor options
            </Li>
        </Ul>

        <H3>Add more rooms</H3>

        <Ul>
            <Li>
                click on the interior door icon{' '}
                <svg viewBox="0 0 470.5 470.5" height="20">
                    <g>
                        <path
                            d="M53.771,0v469.931h34.134V34.134h232.192L185.262,57.91v366.811l185.67,45.779v-67.14h11.664v67.14h34.133V0H53.771z
M382.596,34.134v42.678h-11.664V34.134H382.596z M217.994,258.876c-6.768.0-12.255-7.14-12.255-15.946
c0-8.806,5.487-15.946,12.255-15.946s12.254,7.14,12.254,15.946C230.248,251.736,224.762,258.876,217.994,258.876z
M370.934,350.262V129.909h11.662v220.353H370.934z"
                        ></path>
                    </g>
                </svg>{' '}
                on the toolbar on the left and then on the wall where it should
                located
            </Li>

            <Li>
                an interior door will be added to the selected wall, enter
                doorway parameters (height, width, depth)
            </Li>

            <Li>
                after you have added an interior door, you can start drawing the
                next room, add the following rooms in the same way.
            </Li>
        </Ul>

        <H3>Save drawing</H3>

        <Ul>
            <Li>
                Click the save drawing button, the editor will save the drawing
                to dxf format, then the drawing can be used to work in AutoCAD,
                ArchiCAD, Revit and other programs.
            </Li>
        </Ul>
        <P>
            Send file to mail<A href={`mailto:${email}`}>{email}</A>
        </P>
    </>
)

const IntroductionContentUK = () => (
    <>
        <H3>
            Обміряйте кожну кімнату приміщення по черзі, для креслення потрібні
            такі розміри
        </H3>
        <Ul>
            <Li>висота та ширина дверних та віконних отворів</Li>
            <Li>відстань від кутів кімнати до віконних та дверних отворів</Li>
            <Li>загальну довжину кожної стіни від кута до кута кімнати</Li>
            <Li>відстань від підлоги до стелі</Li>
            <Li>відстань від підлоги до підвіконня</Li>
            <Li>
                розташування витяжок на кухні та ванній, радіаторів опалення,
                стояків, з прив`язкою до найближчої стіни
            </Li>
        </Ul>

        <H3>Кресліть кожну кімнату по черзі, починаючи з передпокою</H3>
        <Ul>
            <Li>
                Спочатку розрахуйте периметр кімнати і переконайтеся, що
                замкнете креслення у єдиний контур.
            </Li>

            <Li>
                Далі введіть висоту, ширину, глибину вхідного дверного отвору
            </Li>

            <Li>
                Далі почніть креслити від правого кута дверей, вибираючи
                стрілками напрями стін та задаючи їх розміри
            </Li>
            <Li>
                Замкніть периметр передпокою в єдиний контур, з`єднавши останній
                відрізок з лівим кутом дверного отвору.
            </Li>
            <p>
                <b>!!!</b> якщо периметр не вдалося замкнути в єдиний контур
                значить десь допущена помилка в розрахунку периметра,
                перерахуйте і накресліть заново
            </p>
        </Ul>

        <H3>Додайте вікна, витяжку, радіатори, труби</H3>

        <Ul>
            <Li>
                після того, як кімната накреслена, додайте предмети з панелі
                інструментів зліва.
            </Li>
            <Li>
                для цього клацніть на об`єкт зліва на панелі інструментів та
                потім на потрібну стіну, а потім введіть параметри прив`язки
            </Li>
        </Ul>

        <H3>Додайте інші кімнати</H3>

        <Ul>
            <Li>
                клікніть на значок міжкімнатні двері {''}
                <svg viewBox="0 0 470.5 470.5" height="20">
                    <g>
                        <path
                            d="M53.771,0v469.931h34.134V34.134h232.192L185.262,57.91v366.811l185.67,45.779v-67.14h11.664v4713.4
M382.596,34.134v42.678h-11.664V34.134H382.596z M217.994,258.876c-6.768,0-12.255-7.14-12.255-15.946
c0-8.806,5.487-15.946,12.255-15.946s12.254,7.14,12.254,15.946C230.248,251.736,224.762,258.876,225.
M370.934,350.262V129.909h11.662v220.353H370.934z"
                        ></path>
                    </g>
                </svg>{' '}
                на панелі інструментів зліва і потім на стіну де вона повинна
                знаходиться
            </Li>

            <Li>
                на обрану стіну додадуться міжкімнатні двері, введіть параметри
                дверного отвору (висота, ширина, глибина)
            </Li>

            <Li>
                після того як ви додали міжкімнатні двері ви можете приступати
                до креслення наступної кімнати, додайте наступні кімнати за
                таким же принципом
            </Li>
        </Ul>

        <H3>Збережіть креслення</H3>

        <Ul>
            <Li>
                Натисніть кнопку зберегти креслення, редактор збереже креслення
                в форматі dxf, далі креслення можна використовувати для роботи в
                AutoCAD, ArchiCAD, Revit та інших програмах.
            </Li>
        </Ul>
        <P>
            Надішліть файл на пошту<A href={`mailto:${email}`}>{email}</A>
        </P>
    </>
)

const IntroductionContentRU = () => (
    <>
        <H3>
            Обмерьте каждую комнату помещения по очереди, для чертежа
            потребуются следующие размеры
        </H3>
        <Ul>
            <Li>высота и ширина дверных и оконных проемов</Li>
            <Li>расстояния от углов комнаты до оконных и дверных проемов</Li>
            <Li>общую длинну каждой стены от угла до угла комнаты</Li>
            <Li>расстояния от пола до потолка</Li>
            <Li>расстояния от пола до подоконника</Li>
            <Li>
                расположение вытяжек в кухни и ванной, радиаторов отопления,
                стояков, с привязкой к ближайшей стене
            </Li>
        </Ul>

        <H3>Чертите каждую комнату по очереди, начиная с прихожей</H3>
        <Ul>
            <Li>
                Сперва рассчитайте периметр комнаты и убедитесь что замкнете
                чертеж в единый контур.
            </Li>

            <Li>
                Далее введите высоту, ширину, глубину входного дверного проема
            </Li>

            <Li>
                Далее начните чертить от правого угла двери, выбирая стрелками
                направления стен и задавая их размеры
            </Li>
            <Li>
                Замкните периметр прихожей в единый контур, соединив последний
                отрезок с левым углом дверного проема.
            </Li>
            <p>
                <b>!!!</b> если периметр не удалось замкнуть в единый контур то
                значит где то допущена ошибка в расчете периметра, пересчитайте
                и начертите заново
            </p>
        </Ul>

        <H3>Добавьте окна, вытяжку, радиаторы, трубы</H3>

        <Ul>
            <Li>
                после того как комната начерчена, добавьте предметы из панели
                инструментов слева.
            </Li>
            <Li>
                для этого кликните на объект слева на панели инструментов и
                затем на нужную стену, затем введите параметры привязки
            </Li>
        </Ul>

        <H3>Добавьте другие комнаты</H3>

        <Ul>
            <Li>
                кликните на значок межкомнатная дверь{' '}
                <svg viewBox="0 0 470.5 470.5" height="20">
                    <g>
                        <path
                            d="M53.771,0v469.931h34.134V34.134h232.192L185.262,57.91v366.811l185.67,45.779v-67.14h11.664v67.14h34.133V0H53.771z
		 M382.596,34.134v42.678h-11.664V34.134H382.596z M217.994,258.876c-6.768,0-12.255-7.14-12.255-15.946
		c0-8.806,5.487-15.946,12.255-15.946s12.254,7.14,12.254,15.946C230.248,251.736,224.762,258.876,217.994,258.876z
		 M370.934,350.262V129.909h11.662v220.353H370.934z"
                        ></path>
                    </g>
                </svg>{' '}
                на панели инструментов слева и затем на стену где она должна
                находится
            </Li>

            <Li>
                на выбранную стену добавится межкомнатная дверь, введите
                параметры дверного проема (высоту, ширину, глубину)
            </Li>

            <Li>
                после того как вы добавили межкомнатную дверь вы можете
                приступать к черчению следующей комнаты, добавляйте следующие
                комнаты по такому же принципу
            </Li>
        </Ul>

        <H3>Сохраните чертеж</H3>

        <Ul>
            <Li>
                Нажмите на кнопку сохранить чертеж, редактор сохранит чертеж в
                формате dxf, далее чертеж можно использовать для работы в
                AutoCAD, ArchiCAD, Revit и др программах.
            </Li>
        </Ul>
        <P>
            Отправьте файл на почту<A href={`mailto:${email}`}>{email}</A>
        </P>
    </>
)

export const introductionContent = (lang: string) => {
    switch (lang) {
        case 'en': {
            return IntroductionContentEN
        }
        case 'uk': {
            return IntroductionContentUK
        }
        case 'ru': {
            return IntroductionContentRU
        }
        default: {
            return IntroductionContentEN
        }
    }
}
