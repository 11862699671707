import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { store } from 'cad/storage/reduser'
import Workplace from 'cad/Workplace'
import { AppHeader } from 'cad/AppHeader'
import { HelmetFunc } from 'components/PageMetaData'
import { light } from 'cad/themes/light'

const pageMetadata = {
    uk: {
        title:
            'Креслити онлайн без реєстрації у форматі .dxf - доступно на смартфоні',
        description:
            'Онлайн редактор для створення обмірних планів квартир, будинків, комерційних приміщень для дизайну інтер`єру та ремонтних робіт, зберігає креслення у форматі .dxf',
    },
    ru: {
        title:
            'Чертить онлайн без регистрации в формате .dxf - доступно на смартфоне',
        description:
            'Онлайн редактор для создания обмерных планов квартир, домов, коммерческих помещений для дизайна интерьера и ремонтных работ, сохраняет чертеж в формате .dxf',
    },
    en: {
        title:
            'Draw online without registration in .dxf format - available on a smartphone',
        description:
            'Online editor for creating measured plans for apartments, houses, commercial premises for interior design and renovation work, saves the drawing in .dxf format',
    },
}

const AppStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    width: 100vw;
    height: 100%;
    max-height: 100vh;
`

const CadPage = () => {
    const [shouldShowAskModal, setShouldShowAskModal] = useState(false)
    const [shouldShowSaveModal, setShouldShowSaveModal] = useState(false)

    return (
        <>
            <HelmetFunc data={pageMetadata} />
            <Provider store={store}>
                <AppStyled>
                    <ThemeProvider theme={light}>
                        <AppHeader
                            setShouldShowAskModal={setShouldShowAskModal}
                            setShouldShowSaveModal={setShouldShowSaveModal}
                        />
                    </ThemeProvider>
                    <Workplace
                        shouldShowAskModal={shouldShowAskModal}
                        setShouldShowAskModal={setShouldShowAskModal}
                        shouldShowSaveModal={shouldShowSaveModal}
                        setShouldShowSaveModal={setShouldShowSaveModal}
                    />
                </AppStyled>
            </Provider>
        </>
    )
}

export default CadPage
