import { GasBoilerType } from 'cad/types'
import { toDxfStringLine } from './lineDxf'

export const toDxfStringGasBoiler = (element: GasBoilerType, color = '20') => {
    const getTextPosition = (firstPoint: number[], secondPoint: number[]) => {
        const [firstPointX, firstPointY] = firstPoint
        const [secondPointX, secondPointY] = secondPoint

        if (firstPointX > secondPointX) {
            return [
                secondPointX + (firstPointX - secondPointX) / 2 - 100,
                firstPointY - 130,
            ]
        }
        if (firstPointX < secondPointX) {
            return [
                firstPointX + (secondPointX - firstPointX) / 2 - 100,
                firstPointY + 50,
            ]
        }
        if (firstPointY > secondPointY) {
            return [
                firstPointX + 200,
                firstPointY - (firstPointY - secondPointY) / 2 + 80,
            ]
        }
        if (secondPointY > firstPointY) {
            return [
                firstPointX - 500,
                secondPointY - (secondPointY - firstPointY) / 2 + 80,
            ]
        }
        return [firstPointX, firstPointY]
    }
    return (
        toDxfStringLine(
            {
                id: element.id,
                points: [...element.points[0], ...element.points[1]],
            },
            color
        ) +
        toDxfStringLine(
            {
                id: element.id,
                points: [...element.points[1], ...element.points[2]],
            },
            color
        ) +
        toDxfStringLine(
            {
                id: element.id,
                points: [...element.points[2], ...element.points[3]],
            },
            color
        ) +
        toDxfStringLine(
            {
                id: element.id,
                points: [...element.points[3], ...element.points[0]],
            },
            color
        ).concat(`0
TEXT
1
${element.points[4]}
8
${element.id}
10
${getTextPosition(element.points[0], element.points[3])[0]}
20
${getTextPosition(element.points[0], element.points[3])[1]}
40
70
`)
    )
}
